import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Select2OptionData } from 'ng-select2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalConfirmComponent } from '../../modals/modal-confirm/modal-confirm.component'
import { ProjectsService } from 'src/app/services/projects/projects.service'
import { TimesheetService } from 'src/app/services/timesheet/timesheet.service'
import { ToastrService } from 'ngx-toastr'
import { SkillsService } from 'src/app/services/skills/skils.service'
import * as moment from 'moment'
import { HelpersService } from '../../../services/helpers/helpers.service'
import { User } from '../../../models/user'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../../../state/app-state'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

@Component({
	selector: 'app-timesheet-control',
	templateUrl: './timesheet-control.component.html',
	styleUrls: ['./timesheet-control.component.scss'],
})
export class TimesheetControlComponent implements OnInit {
	@Output() emmitEventUpdateList: EventEmitter<any> = new EventEmitter()
	@Input() timesheets: any
	@Input() date: string = ''
	@Input() weekday: string = ''
	private user$?: Observable<User>
	user?: User
	totalHours: number = 0
	isDayOff: boolean = false
	isSkillFirstProject: boolean = false
	isSkillFirstProjectId: string = ''
	loading: boolean = false
	edit: boolean = false
	_force: boolean = true
	hasFile: boolean = false
	fileNamePdf: string = ''
	projectAbsent: any = null
	projectStudy: any = null
	isAbsent = false
	limitExceededNormalHour = false
	temporaryTimerUpdate = 0
	isStudy = false
	loadingFunction = false
	normalTimeControl = true
	action: any = {
		type: 'post',
		id: '',
	}
	listTimeSheets: any[] = []
	hasSkillsOptionsUser: any = []
	timesheet: any = {}
	form: FormGroup
	listProjects: Array<Select2OptionData> = []
	listFunctions: Array<Select2OptionData> = []
	optionsTimeSelect: any[] = [
		{ value: '1', label: '1' },
		{ value: '2', label: '2' },
		{ value: '3', label: '3' },
		{ value: '4', label: '4' },
		{ value: '5', label: '5' },
		{ value: '6', label: '6' },
		{ value: '7', label: '7' },
		{ value: '8', label: '8' },
	]
	absenceReason: any[] = [
		{ value: 'dayoff', label: 'Dayoff' },
		{ value: 'folga', label: 'Folga' },
		{ value: 'consulta_medica_odonto', label: 'Consulta médica/Odonto' },
		{ value: 'atestado', label: 'Atestado' },
	]

	constructor(
		private modalService: NgbModal,
		private timesheetService: TimesheetService,
		private toast: ToastrService,
		private projectsService: ProjectsService,
		private skillsService: SkillsService,
		public helpers: HelpersService,
		private store: Store<AppState>,
		public formBuilder: FormBuilder
	) {
		this.user$ = this.store.select('user')
		this.user$.subscribe((state) => {
			this.user = state
		})

		this.form = this.formBuilder.group({
			project_id: ['', [Validators.required]],
			hours: [1, [Validators.required]],
			date: [null, [Validators.required]],
			function: [null, []],
			hours_charged: [false, []],
			overtime: [false, []],
			absence_reason: ['', [Validators.required]],
			uuid: [null, []],
			absence_file: [null, []],
		})
	}
	ngOnInit(): void {
		this.form.value.date = this.date
		this.setTimesheetUpdate()

		const list = this.user?.skills.map((skill: any) => ({
			text: skill.group.name,
			id: skill.group.name,
		}))

		this.hasSkillsOptionsUser = this.getUniqueListBy(list)

		this.getAllProjects()

		if (list.length > 0) {
			this.getSkillsByProject()
		}
	}
	setValueTime(event: any) {
		const { value } = event.target
		this.form.value.hours = value
	}
	ChangeValueAbsenceReason(event: any) {
		const { value } = event.target
		this.form.value.absence_reason = value

		this.disabledSubmit()

		if (value === 'dayoff') {
			this.form.value.hours = 8
			this.isDayOff = true
		} else {
			this.form.value.hours = 1
			this.isDayOff = false
		}
	}
	setTimesheetUpdate() {
		if (this.timesheets && this.timesheets.length) {
			this.totalHours = 0
			this.listTimeSheets = this.timesheets.map((timesheet: any) => {
				const { extendedProps } = timesheet
				this.totalHours = this.totalHours + extendedProps.hours

				return {
					hours: extendedProps.hours || 0,
					name: extendedProps.project.name,
					id: extendedProps.project.uuid,
					projects_id: extendedProps.project.uuid,
					hours_charged: extendedProps.hours_charged,
					absence_reason: extendedProps.absence_reason,
					absence_file: extendedProps.absence_file,
					uuid: extendedProps.uuid || '',
					overtime: extendedProps.overtime || false,
					function: extendedProps.function || '',
				}
			})
		}
	}
	setCurrentTimesheet(currentTimesheet: any) {
		this.resetForm()
		setTimeout(() => {
			this.getSkillsByProject(currentTimesheet.projects_id)

			this.form.setValue({
				project_id: currentTimesheet.projects_id,
				hours: currentTimesheet.hours || 0,
				date: this.date,
				hours_charged: currentTimesheet.hours_charged || false,
				absence_reason: currentTimesheet.absence_reason,
				absence_file: currentTimesheet.absence_file,
				uuid: currentTimesheet.uuid || '',
				overtime: currentTimesheet.overtime,
				function: currentTimesheet.function || '',
			})

			if (!this.form.value.overtime) {
				this.normalTimeControl = !this.form.value.overtime
			}

			this.action.type = 'update'
			this.action.id = currentTimesheet.uuid
			this.isAbsent = this.projectAbsent[0].uuid == currentTimesheet.id
			this.isStudy = this.projectStudy[0].uuid == currentTimesheet.id
			this.isDayOff = currentTimesheet.absence_reason == 'dayoff'
			this.temporaryTimerUpdate = currentTimesheet.hours || 0
		})
	}
	getAllProjects() {
		const self = this
		this.projectsService.getProjectByClient({
			fnSuccess({ data }) {
				self.listProjects = data.map((project: any) => ({
					text: project.full_name,
					id: project.uuid,
				}))

				self.projectAbsent = data.filter((project: any) => {
					return project.name.toLowerCase() == 'ausente'
				})

				self.projectStudy = data.filter((project: any) => {
					return project.name.toLowerCase() == 'estudos'
				})
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
	fileChangeEvent(event: any): void {
		if (event.target.files && event.target.files[0]) {
			this.form.value.absence_file = event.target.files[0]
			this.hasFile = true
		} else {
			this.fileNamePdf = ''
		}
	}
	removeFile(edit: boolean = true) {
		if (edit) this.timesheet.absence_file = ''
		this.fileNamePdf = ''
		this.forceRender()
	}
	setTypeDayWorkerNormal(event: any) {
		this.form.value.overtime = false
		this.normalTimeControl = true

		if (this.isAbsent || this.isStudy) {
			this.resetForm()
		}
	}
	setTypeDayWorkerOvertime(event: any) {
		this.form.value.overtime = true
		this.normalTimeControl = false

		if (this.isAbsent || this.isStudy) {
			this.resetForm()
		}
	}
	setTypeDayWorkerAbsent(event: any) {
		this.isAbsent = true
		this.isStudy = false
		this.normalTimeControl = false
		this.setValueForm(this.projectAbsent[0])
	}
	setTypeDayWorkerStudy(event: any) {
		this.isStudy = true
		this.isAbsent = false
		this.isDayOff = false
		this.normalTimeControl = false
		this.form.value.overtime = false
		this.setValueForm(this.projectStudy[0])

		if (!this.hasSkillsOptionsUser.length) {
			this.getSkillsByProject(this.form.value.project_id)
		}
	}
	changeProject(projectId: any) {
		this.form.value.project_id = projectId
		if (!this.hasSkillsOptionsUser.length) {
			this.getSkillsByProject(projectId)
		}
	}
	changeSkill(event: any) {
		this.form.value.function = event
	}
	getSkillsByProject(projectId?: string, callback?: Function) {
		const self = this
		if (this.hasSkillsOptionsUser.length == 0) {
			if (projectId) {
				self.loadingFunction = true
				this.skillsService.getGroupSkillsByProject(projectId, {
					fnSuccess(data) {
						const { functions } = data

						if (callback) callback()

						if (functions && functions.length) {
							self.listFunctions = functions.map((fn: any) => ({
								text: fn,
								id: fn,
							}))
						}

						self.isSkillFirstProject = false
						self.loadingFunction = false
					},
					fnError(err) {
						console.warn('Erro ao buscar skills por projeto', err)
						self.loadingFunction = false
					},
				})
			}
		} else {
			self.listFunctions = self.hasSkillsOptionsUser
			if (this.hasSkillsOptionsUser.length > 1) {
				self.form.value.function = self.listFunctions[0].id
				self.isSkillFirstProjectId = ''
				self.isSkillFirstProject = false
			} else {
				self.form.value.function = self.listFunctions[0].id
				self.isSkillFirstProjectId = self.listFunctions[0].id
				this.isSkillFirstProject = true
			}
			self.loadingFunction = false
		}
	}
	showModalConfirm(message: string, callback: Function) {
		const modalRef = this.modalService.open(ModalConfirmComponent, {
			container: '#modal-timesheet',
			windowClass: 'modal-confirm',
		})

		modalRef.componentInstance.title = message
		modalRef.componentInstance.callbackConfirmation = () => {
			callback()
			modalRef.close()
		}
	}
	createTimesheet(dataTimesheet: any) {
		const self = this
		this.loading = true
		let data = {}

		if (self.form.value.absence_reason) {
			const hasFile = !!self.form.value.absence_file
			data = this.constructFormData(dataTimesheet, !hasFile)
		} else {
			data = dataTimesheet
			data = {
				...data,
				overtime: this.form.value.overtime,
			}
		}

		this.timesheetService.createTimesheet(data, {
			fnSuccess() {
				self.emmitEventUpdateList.emit()
				self.setTimesheetUpdate()
				self.resetForm()
			},
			fnError(err) {
				console.warn('Error create timesheet', err)
				self.toast.error('Algum erro ocorreu ao anotar sua hora')
				self.showErrorsTimesheet(err)
				self.loading = false
			},
			fnFinalized() {
				self.loading = false
			},
		})
	}
	updateTimesheet(dataTimesheet: any) {
		const self = this
		this.loading = false
		let data: any = {}

		if (self.form.value.absence_reason == 'ausente') {
			const hasFile = !!self.form.value.absence_file
			data = this.constructFormData(dataTimesheet, !hasFile)
		} else {
			data = dataTimesheet

			delete data['absence_file']
		}

		this.timesheetService.updateTimesheet(dataTimesheet?.uuid, data, {
			fnSuccess() {
				self.resetForm()
				self.emmitEventUpdateList.emit()
			},
			fnError(err) {
				self.toast.error('Algum erro ocorreu ao anotar sua hora')
				console.warn('Error update timesheet', err)
			},
			fnFinalized() {
				self.loading = false
			},
		})
	}
	deleteTimesheet(dataTimesheet: any) {
		const self = this
		const { uuid } = dataTimesheet
		const messageModal = 'Tem certeza que deseja deletar esse apontamento?'

		const oldDay = this.isOldDay()

		if (!oldDay) {
			this.showModalConfirm(messageModal, () => {
				this.loading = true

				this.timesheetService.deleteTimesheet(uuid, {
					fnSuccess() {
						self.resetForm()
						self.emmitEventUpdateList.emit()
					},
					fnError(err) {
						self.showErrorsTimesheet(err)
						self.loading = false
					},
				})
			})
		} else {
			this.toast.warning(
				'em caso de erro de valores, comunique seu lider imediato!',
				'Você não pode deletar datas anteriores'
			)
		}
	}
	constructFormData(dataTimesheet: any, update: boolean = false) {
		const formData = new FormData()
		if (!update) {
			formData.append('absence_file', dataTimesheet.absence_file)
		}
		formData.append('absence_reason', dataTimesheet.absence_reason)
		formData.append('date', dataTimesheet.date)
		formData.append('function', dataTimesheet.function)
		formData.append('hours', dataTimesheet.hours)
		formData.append('hours_charged', dataTimesheet.hours_charged)
		formData.append('overtime', dataTimesheet.overtime || false)
		formData.append('project_id', dataTimesheet.project_id)
		formData.append('uuid', dataTimesheet.uuid)

		return formData
	}
	disabledSubmit() {
		let disabled = false
		let list = this.listFunctions.filter(
			(el: any) => el.text == this.form.value.function
		)

		if (!this.form.value.project_id || !this.form.value.function) {
			disabled = true
		}

		if (this.isAbsent) {
			disabled = !this.form.value.absence_reason
		}

		if (this.isStudy) {
			disabled = !this.isStudy
		}

		// if (this.totalHours >= 8 && this.limitExceededNormalHour) {
		// 	this.limitExceededNormalHour = true
		// 	disabled = !this.form.value?.overtime
		//
		// 	if (!this.form.value.project && !this.form.value.function) {
		// 		disabled = true
		// 	}
		// }

		// if (
		// 	this.totalHours + parseInt(this.form.value.hours) > 8 &&
		// 	!this.form.value?.overtime
		// ) {
		// 	disabled = this.totalHours + this.form.value.hours > 8
		// }

		if (this.form.value.uuid) {
			disabled = false

			if (
				this.totalHours -
					this.temporaryTimerUpdate +
					parseInt(this.form.value.hours) -
					1 >
				7
			) {
				disabled = !this.form.value?.overtime
			}

			disabled = list.length == 0 && !this.isAbsent

			// if (!this.form.value?.overtime)
			// 	disabled =
			// 		this.totalHours -
			// 			this.temporaryTimerUpdate +
			// 			parseInt(this.form.value.hours) >
			// 		8
		}

		return disabled
	}
	setValueForm(currentTimesheet: any) {
		this.form.setValue({
			project_id: currentTimesheet.uuid,
			hours: 1,
			date: this.date,
			hours_charged: currentTimesheet.hours_charged || false,
			absence_reason: null,
			absence_file: currentTimesheet.absence_reason
				? currentTimesheet.absence_reason
				: null,
			uuid: null,
			overtime: currentTimesheet.overtime || false,
			function: currentTimesheet.function || '',
		})
	}
	isOldDay() {
		const dateNow: Date | any = new Date()
		const currentDate: Date | any = new Date(this.date)

		let yesterday: any = moment(dateNow).add(-14, 'days')

		const currentDateInSeconds = moment().add(currentDate, 'second')
		const yesterdayInSeconds = moment().add(yesterday, 'second')

		console.log(12121, yesterdayInSeconds > currentDateInSeconds)

		return yesterdayInSeconds > currentDateInSeconds
	}
	getUniqueListBy(arr: any) {
		return [
			...new Map(arr.map((item: any) => [item['text'], item])).values(),
		]
	}
	showErrorsTimesheet(error: any) {
		if (error.error) {
			if (error.error.includes('higher than 24')) {
				this.toast.warning(
					'A sua quantidade de horas por dia não pode ser superior a 24'
				)
				return
			}
			if (error.error.includes('deleting past dates')) {
				this.toast.warning('Você não pode deletar datas anteriores')
				return
			}
			this.toast.error(error.error)
		}
	}
	saveAction(remove: boolean = false) {
		if (remove) this.action.type = 'delete'

		const oldDay = this.isOldDay()
		if (!oldDay) {
			switch (this.action.type) {
				case 'post':
					this.createTimesheet(this.form.value)
					break
				case 'update':
					this.updateTimesheet(this.form.value)
					break
				case 'delete':
					this.deleteTimesheet(this.form.value)
					break
			}
		} else {
			console.log(this.action.type)
			this.toast.warning(
				'Em caso de erro de valores, comunique seu lider imediato!',
				`Somente entradas de até 14 dias atrás podem ser ${
					remove
						? 'deletadas'
						: this.action.type === 'post'
						? 'criadas'
						: 'editadas'
				}.`
			)
		}
	}
	close(fnCloseModal: Function) {
		const messageModal =
			'Seu apontamento diário não atingiu as 8 horas mínimas, deseja sair mesmo assim?'

		if (this.listTimeSheets.length) {
			if (this.totalHours < 8) {
				this.showModalConfirm(messageModal, () => {
					fnCloseModal()
				})
			} else {
				fnCloseModal()
			}
		} else {
			fnCloseModal()
		}
	}
	resetForm() {
		this.form.setValue({
			project_id: '',
			hours: 1,
			date: this.date,
			function: this.isSkillFirstProjectId
				? this.isSkillFirstProjectId
				: null,
			hours_charged: false,
			overtime: false,
			absence_reason: null,
			uuid: null,
			absence_file: null,
		})

		this.normalTimeControl = false
		setTimeout(() => {
			this.normalTimeControl = true
		})
		this.isDayOff = false
		this.isAbsent = false
		this.isStudy = false
		this.action.type = 'post'
		this.action.id = ''
		this.temporaryTimerUpdate = 0
	}
	forceRender() {
		this._force = false
		setTimeout(() => {
			this._force = true
		}, 2000)
	}
}
